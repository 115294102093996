import {ref} from "vue";
import BarberService from "@/service/BarberService";
import moment from "moment";

export function useBarberList() {
    const barbers = ref([]);
    const totalBarbers = ref(0);
    const barberService = new BarberService();

    const getBarbers = async (query) => {
        const res = await barberService.getAll(query)
        const data = res.data
        barbers.value = data.barbers
        totalBarbers.value = data.total
    }

    return {barbers, totalBarbers, getBarbers}
}

export function useBarberForm() {
    const barberService = new BarberService();

    const create = async (data) => {
        return await barberService.create(data)
    }

    const submitNIK = async (barberID, data) => {
        await barberService.createBarberInfo({
            barber_id: barberID,
            info_name: "NIK",
            info_value: data
        })
    }

    const update = async (id, data) => {
        await barberService.update(id, data)
    }

    const getDetail = async (id) => {
        const res = await barberService.getOne(id)
        const tempData = {}
        Object.keys(res.data).forEach((key) => {
            if (typeof res.data[key] === 'boolean') tempData[key] = res.data[key]
            else tempData[key] = String(res.data[key])
        })
        return tempData
    }

    return {create, update, getDetail, submitNIK}
}

export function useBarberService(barberId) {
    const barberServices = ref([])
    const barberService = new BarberService()

    const getBarberService = async (salonName) => {
        const res = await barberService.getBarberService(barberId)
        const data = res.data

        const temp = []
        data?.barber_services.forEach((item, idx) => {
            temp.push({...item, no: idx + 1, salon_name: salonName})
        })
        barberServices.value = temp
    }

    const create = async (barberId, data) => {
        return await barberService.createBarberService(barberId, data)
    }

    const update = async (barberId, barberServiceId, data) => {
        return await barberService.updateBarberService(barberId, barberServiceId, data)
    }

    const deleteBarberService = async (id) => {
        return await barberService.deleteBarberService(id)
    }

    return {barberServices, getBarberService, create, update, deleteBarberService}
}

export function useBarberAvailability(barberId) {
    const barberAvailabilities = ref([])
    const barberService = new BarberService();

    const getBarberAvailability = async (salonName) => {
        const res = await barberService.getBarberAvailability(barberId)
        const data = res.data

        const temp = []
        data?.barber_availabilities.forEach((item, idx) => {
            const start = moment(item.start).locale('id_ID').format("DD/MM/YYYY HH:mm")
            const end = moment(item.end).locale('id_ID').format("DD/MM/YYYY HH:mm")
            temp.push({...item, no: idx + 1, salon_name: salonName, start, end})
        })
        barberAvailabilities.value = temp
    }

    const create = async (data) => {
        await barberService.createBarberAvailability(data)
    }

    const createBulk = async (id, data) => {
        await barberService.createBarberAvailabilityBulk(id, data)
    }

    const update = async (barberId, barberAvailabilityId, data) => {
        await barberService.updateBarberAvailability(barberId, barberAvailabilityId, data)
    }

    const deleteBarberAvailability = async (id) => {
        return await barberService.deleteBarberAvailability(id)
    }

    return {barberAvailabilities, getBarberAvailability, create, update, createBulk, deleteBarberAvailability}
}

export function useBarberInfo(barberId) {
    const barberInfos = ref([])
    const barberService = new BarberService();

    const getBarberInfos = async () => {
        const res = await barberService.getBarberInfos(barberId)
        const data = res.data

        const temp = []
        data?.forEach((item, idx) => {
            temp.push({...item, no: idx + 1})
        })
        barberInfos.value = temp
    }

    const create = async (data) => {
        await barberService.createBarberInfo(data)
    }

    const update = async (barberInfoId, data) => {
        await barberService.updateBarberInfo(barberInfoId, data)
    }

    const remove = async (barberInfoId) => {
        await barberService.deleteBarberInfo(barberInfoId)
    }

    return {barberInfos, getBarberInfos, create, update, remove}
}
