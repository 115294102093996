<template>
  <div class="tw-p-6 md:tw-p-2 tw-drop-shadow-md tw-border-b-2">
    <div
      class="tw-flex tw-items-center tw-justify-between tw-mb-6 tw-ml-3"
      v-if="is_create"
    >
      <router-link
        :to="{ name: 'barbers' }"
        class="text-lg tw-font-semibold tw-text-blue-700 tw-hover:tx-text-blue-900"
      >
        <i class="mr-2 pi pi-arrow-left"></i>
        Kembali
      </router-link>
      <div class="">
        <Button
          label="Batal"
          @click="$router.push({ name: 'barbers' })"
          class="tw-h-11 tw-text-sm tw-mr-3 tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300"
        />
        <Button
          label="Simpan"
          icon="pi pi-check"
          @click="submitData"
          class="tw-h-11 tw-text-sm tw-mr-3"
        />
      </div>
    </div>
    <h5 class="tw-text-xl inter-semibold tw-text-black">
      {{ title }}
    </h5>
  </div>

  <FormKit
    type="form"
    id="barbers"
    :actions="false"
    v-model="initValue"
    :classes="{ messages: 'tw-text-red-500 tw-text-center tw-mt-4' }"
    @submit="submit"
    :style="formStyles"
    >
    <div class="form-field">
      <label class="form-title" for="name"
        >Nama Pegawai <span class="required-label">*</span></label
      >
      <FormKit
        name="name"
        type="text"
        validation="required"
        validation-visibility="dirty"
        :classes="defaultFormikListClass"
      />
    </div>
    <div class="form-field" v-if="is_create">
      <label class="form-title" for="nik">No KTP</label>
      <FormKit
        name="nik"
        type="text"
        validation-visibility="dirty"
        :classes="defaultFormikListClass"
      />
    </div>
    <div class="form-field">
      <label class="form-title" for="position"
        >Posisi <span class="required-label">*</span></label
      >
      <FormKit
        name="position"
        type="text"
        validation="required"
        validation-visibility="dirty"
        :classes="defaultFormikListClass"
      />
    </div>

    <div class="form-field" v-if="!isAdminSalon()">
      <label class="form-title" for="salon_id"
        >Salon <span class="required-label">*</span></label
      >
      <FormKit
        name="salon_id"
        type="select"
        validation="required"
        placeholder="Pilih Salon"
        :classes="defaultFormikListClass"
        :options="salons"
      >
        <template #input="context">
          <div class="p-fluid">
            <Dropdown
              v-model="context.value"
              @change="
                (e) => {
                  context.node.input(e.value);
                }
              "
              :options="context.options"
              optionLabel="label"
              optionValue="value"
              :placeholder="context.placeholder"
              :disabled="isAdminSalon()"
            >
              <template #option="optionContext">
                <option
                  v-if="!optionContext.option.attrs?.hidden"
                  :key="optionContext.index"
                  :value="optionContext.option.value"
                >
                  {{ optionContext.option.label }}
                </option>
                <option
                  v-else
                  :key="optionContext.index + '-disabled'"
                  :disabled="true"
                  :value="optionContext.option.value"
                >
                  Not Selected
                </option>
              </template>
            </Dropdown>
          </div>
        </template>
      </FormKit>
    </div>

    <div class="form-field">
      <label class="form-title" for="gender"
        >Jenis Kelamin <span class="required-label">*</span></label
      >
      <FormKit
        name="gender"
        type="radio"
        validation="required"
        :classes="defaultFormikListClass"
      >
        <template #input="context">
          <div class="radio-group">
            <input
              type="radio"
              :id="'gender-M'"
              name="gender"
              value="M"
              v-model="context.value"
              @change="
                (e) => {
                  context.node.input(e.target.value);
                }
              "
              required
            />
            <label for="gender-M" class="radio-label">Laki-Laki</label>
            <br />
            <input
              type="radio"
              :id="'gender-F'"
              name="gender"
              value="F"
              v-model="context.value"
              @change="
                (e) => {
                  context.node.input(e.target.value);
                }
              "
              required
            />
            <label for="gender-F" class="radio-label">Perempuan</label>
          </div>
        </template>
      </FormKit>
    </div>

    <div class="form-field">
      <label class="form-title" for="status"
        >Status <span class="required-label">*</span></label
      >
      <FormKit
        name="status"
        type="radio"
        validation="required"
        :classes="defaultFormikListClass"
      >
        <template #input="context">
          <div class="p-fluid">
            <div class="radio-group">
              <input
                type="radio"
                :id="'status-1'"
                name="status"
                value="1"
                v-model="context.value"
                @change="
                  (e) => {
                    context.node.input(e.target.value);
                  }
                "
                required
              />
              <label for="status-1" class="radio-label">Aktif</label>
              <br />
              <input
                type="radio"
                :id="'status-0'"
                name="status"
                value="0"
                v-model="context.value"
                @change="
                  (e) => {
                    context.node.input(e.target.value);
                  }
                "
                required
              />
              <label for="status-0" class="radio-label">Tidak Aktif</label>
            </div>
          </div>
        </template>
      </FormKit>
    </div>

    <div class="form-field">
      <label class="form-title" for="hide_rating"
        >Rating <span class="required-label">*</span></label
      >
      <div class="tw-flex tw-my-4">
        <div class="toggle-switch">
          <input
            type="checkbox"
            id="hide_rating"
            name="hide_rating"
            class="toggle-input"
          />
          <label for="hide_rating" class="toggle-label"></label>
        </div>
        <label for="hide_rating" class="tw-ml-4">Tampilkan</label>
      </div>
    </div>
  </FormKit>

  <div
    class="tw-w-full tw-flex tw-justify-between bg-blend-darken"
    v-if="!is_create"
  >
    <Button
      label="Batal"
      @click="cancel"
      class="tw-w-full tw-text-sm tw-mr-3 tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300"
    />
    <Button
      label="Simpan"
      @click="submitData"
      class="tw-w-full tw-text-sm tw-mr-3"
    />
  </div>
</template>

<script setup>
import { defineEmits, defineProps, onBeforeMount, ref, watch, computed, onMounted } from "vue";
import { submitForm } from "@formkit/vue";
import { defaultFormikListClass } from "@/constants/formik";
import { useSalonDropdown } from "@/composables/salon";
import { useAuthRole } from "@/composables/auth";
import { useBarberForm } from "@/composables/barber";

const windowWidth = ref(window.innerWidth);

const props = defineProps({
  initialData: {
    type: Object,
    required: false,
  },
  is_create: {
    type: Boolean,
    required: true,
  },
  title: {
    type: String,
    required: false,
    default: "Tambah Pegawai",
  },
});
const initValue = ref(props.initialData);
const isCreate = ref(props.is_create);

const { isAdminSalon, salonId } = useAuthRole();
const { salons } = useSalonDropdown();
const emit = defineEmits(["submit"]);
const { getDetail } = useBarberForm();

const submit = (data) => {
  const formData = {
    ...data,
    salon_id: parseInt(data.salon_id),
    status: parseInt(data.status),
    hide_rating: data.hide_rating || false,
  };
  emit("submit", formData);
};

const cancel = () => {
  // Emit event untuk memberitahu komponen induk bahwa tombol "Batal" diklik
  emit("cancel");
};

onMounted(() => {
  window.addEventListener('resize', handleResize);
});

onBeforeMount(async () => {
  if (isAdminSalon()) {
    initValue.value = {
      salon_id: String(salonId.value),
    };
  }
  initValue.value = await getDetail(props.initialData.id);
  window.removeEventListener('resize', handleResize);
});

watch(
  () => props.initialData,
  (newData) => {
    initValue.value = newData;
    isCreate.value = false;
  }
);


const formStyles = computed(() => ({
  width: windowWidth.value <= 768 ? '100%' : '50%',
  justifyContent: 'center',
  margin: '2rem auto',
}));

const handleResize = () => {
  windowWidth.value = window.innerWidth;
};

const submitData = () => {
  submitForm("barbers");
};
</script>
<style scoped>
.required-label {
  color: red;
}

.form-field {
  display: flex;
  flex-direction: column;
}

.form-field .form-title,
.form-field span {
  font-weight: bold;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
}

.toggle-input {
  display: none;
}

.toggle-label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggle-label:after {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.toggle-input:checked + .toggle-label {
  background-color: #0071bc;
}

.toggle-input:focus + .toggle-label {
  box-shadow: 0 0 1px #0071bc;
}

.toggle-input:checked + .toggle-label:after {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}
</style>
